<template>
    <v-expansion-panels>
        <v-expansion-panel>
            <v-expansion-panel-header class="card-title mt-1">
                Filters
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <v-row>
                    <v-col cols="12" lg="3">
                        <v-switch
                            v-model="active"
                            :label="active ? 'Active' : 'All Teams'"
                        />
                    </v-col>
                    <v-col cols="12" lg="3">
                        <v-text-field
                            v-model="name"
                            menu-props="auto"
                            label="Search team by name"
                            prepend-inner-icon="mdi-magnify"
                            hide-details
                            clearable
                            return-object
                        />
                    </v-col>
                    <v-col cols="12" lg="3" align="end">
                        <v-btn class="my-2 mr-2" dark small color="black" @click="clearFilters">
                            <v-icon dark>mdi-close</v-icon>Clear filters
                        </v-btn>
                    </v-col>
                    <v-col cols="12" lg="3" align="start">
                        <v-btn class="my-2 mr-2" dark small color="black" @click="applyFilters">
                            <v-icon dark>mdi-filter</v-icon>Apply filters
                        </v-btn>
                    </v-col>
                </v-row>
            </v-expansion-panel-content>
        </v-expansion-panel>
    </v-expansion-panels>
</template>

<script>
    export default {
        name: 'TeamFilters',
        data: () => {
            return {
                name: null,
                active: null,
                filters: null
            }
        },
        watch: {
            filters: {
                handler() {
                    this.setFilters()
                }
            }
        },
        created() {
            this.filters = this.$store.getters.getTeamFilters
        },
        methods: {
            setFilters() {
                this.filters = decodeURIComponent(this.filters)
                this.filters = this.filters.replace(/[|&;$%@<>()+=?]/g, '').replace(/filter/g, '')
                let query = JSON.parse(this.filters)
                for (const property in query) {
                    switch (property) {
                    case 'name':
                        this.name = query[property]
                        break
                    case 'active':
                        this.active = query[property]
                        break
                    default:
                        console.log('there is no such filter')
                    }
                }
            },
            clearFilters() {
                this.name = null
                this.active = null
                this.filters = null
                this.$emit('setFilters', null)
            },
            applyFilters() {
                let query = ''
                if (this.name) {
                    query += `"name":"%${this.name}%",`
                }
                if (this.active !== null) {
                    query += `"active":${this.active},`
                }
                this.filters = `?filter=${encodeURIComponent('{' + query.slice(0, -1) + '}')}`
                this.$emit('setFilters', this.filters)
            }
        }
    }
</script>
