<template>
    <v-row class="py-0 my-0 px-0 mx-0">
        <v-col cols="12" align="start" class="py-0 my-0 px-0 mx-0">
            <v-switch
                v-model="register"
                prepend-icon="mdi-basketball-hoop-outline"
                :label="register ? 'Registrations Open' : 'Registrations Closed'"
                @change="tongleRegister"
            />
        </v-col>
    </v-row>
</template>

<script>
    import registrationService from '@/services/registration'
    export default {
        data() {
            return {
                register: false
            }
        },
        created() {
            this.getRegisterStatus()
        },
        methods: {
            tongleRegister() {
                registrationService.registrationTongle().then((response) => {
                    this.register = response.data
                }).catch((error) => {
                    console.log(error)
                })
            },
            getRegisterStatus() {
                registrationService.getRegisterStatus().then((response) => {
                    this.register = response.data.registrations
                }).catch((error) => {
                    console.log(error)
                })
            }
        }
    }
</script>
