import api from '@/utils/api'

export default {
    registrationTongle() {
        return api.post('config/register-toggle')
    },
    getRegisterStatus() {
        return api.get(`config`)
    }
}
