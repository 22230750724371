<template>
    <v-container fluid>
        <v-card class="header-card">
            <v-card-title>
                <v-row align="center">
                    <v-col cols="3" lg="4" align="start">
                        Teams
                    </v-col>
                    <v-col cols="9" lg="4" align="center" class="my-0 py-0">
                        <registration />
                    </v-col>
                    <v-col cols="12" lg="4" align="end">
                        <v-btn class="my-2 mr-2" dark small color="black" @click="createTeam">
                            <v-icon dark>mdi-plus</v-icon>Create
                        </v-btn>
                    </v-col>
                    <v-col cols="12" align="center">
                        <team-filters @setFilters="setFilters"/>
                    </v-col>
                </v-row>
            </v-card-title>
        </v-card>
        <array-view
            :items="teams"
            :headers="displayInfo"
            :total-pages="totalPages"
            :page="page"
            :size="size"
            @handleClick="handleClick"
            @paginationChange="paginationChange"
            @onDelete="onDelete"
        />
    </v-container>
</template>

<script>
    import teamService from '@/services/team'
    import ArrayView from '@/components/ArrayView'
    import Registration from '@/forms/Registration'
    import TeamFilters from '@/components/TeamFilters'

    export default {
        name: 'Team',
        components: { ArrayView, Registration, TeamFilters },
        data: () => {
            return {
                successfullImport: null,
                teams: [],
                displayInfo: [
                    {
                        text: 'Id',
                        value: 'id'
                    },
                    {
                        text: 'Name',
                        value: 'name'
                    },
                    {
                        text: 'Active',
                        value: 'active'
                    }
                ],
                showImportDialog: false,
                size: 5,
                totalPages: 0,
                page: 1,
                search: null,
                loading: false,
                isActive: false,
                filters: null
            }
        },
        watch: {
            filters: {
                handler() {
                    console.log(this.filters)
                    this.fetchFilteredTeams()
                }
            }
        },
        created() {
            this.page = this.$store.getters.getPage
            this.filters = this.$store.getters.getTeamFilters
            this.fetchFilteredTeams()
        },
        methods: {
            setFilters(filters) {
                this.filters = filters
                this.$store.dispatch('setTeamFilters', this.filters)
            },
            resetPage() {
                this.paginationChange(1, this.size)
            },
            paginationChange(page, size) {
                this.size = size
                this.page = page
                this.$store.dispatch('setPage', page)
                this.fetchFilteredTeams()
            },
            handleClick(action, item) {
                this.$router.push(`/teams/${item.id}/${action}`)
            },
            createTeam() {
                this.$router.push(`/teams/create`)
            },
            fetchAllTeams() {
                teamService.getTeams(this.page, this.size).then(resp => {
                    this.teams = resp.data.content
                    this.totalPages = resp.data.totalPages
                })
            },
            fetchFilteredTeams() {
                this.loading = true
                teamService.getFilteredTeams(this.filters, this.page, this.size).then(resp => {
                    this.teams = resp.data.content
                    this.totalPages = resp.data.totalPages
                    this.loading = false
                })
            },
            onDelete(item, page) {
                teamService.deleteTeam(item.id).then(() => {
                    this.page = page
                    this.fetchAllTeams()
                })
            }
        }
    }
</script>

<style scoped>

.v-list-item {
    min-height: 30px !important;
}

.v-card {
    min-width: 250px;
    position: relative;
}
.edit-button:hover {
    background-color: whitesmoke;
    color: white;
}

.screen-card {
    padding: 4%;
}

.overflow-text {
    max-width: 79%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.pagination {
    justify-content: center;
}
</style>
